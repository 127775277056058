import { logEvent } from '@/helper/gtm-webview';
import fetchPurchaseGA4CategoriesAndBrands from '@/api/ga4/purchase-ga4';
// import { blackLogStyle } from '@/helper/debug';

/**
 * google tag manager
 */

export const getGtmDataLayer = (gtmInstance) => {
  if (!gtmInstance) return [];
  if (!gtmInstance.enabled()) return [];
  return window.dataLayer || [];
};

/**
 * gtm add datalayer
 * @param {object} option option
 * @param {object|undefined} gtm gtm object
 */
export const gtmTransaction = (option = {}, gtm) => {
  const send = getGtmDataLayer(gtm);
  if (option.event === undefined) option.event = 'gtm.transaction';

  send.push(option);
  logEvent('gtm_transaction', option);
};

/**
 * gtm add ga4 datalayer
 * @param {string} eventName
 * @param {object} ecommerceOption
 * @param {object|undefined} gtm gtm object
 */
export const gtmGA4EcommerceEvent = (eventName, ecommerceOption = {}, gtm) => {
  const send = getGtmDataLayer(gtm);
  const option = { event: eventName, ecommerce: ecommerceOption };

  send.push({ ecommerce: null }); // Clear the previous ecommerce object.
  send.push(option);
  logEvent(eventName, ecommerceOption);
};

/**
 * gtmGA4EcommerceEventNew 建立 params 參數
 * @param {{event: string, ga4EventName: string, ecommerceOption: object}} params parameters for GA4
 */
export const gtmGA4EcommerceEventNew = (params, gtm) => {
  const { event, ga4EventName, ecommerceOption } = params;
  const send = getGtmDataLayer(gtm);
  const option = {
    event,
    ga4_event_name: ga4EventName,
    ecommerce: ecommerceOption,
  };

  send.push({ ecommerce: null }); // Clear the previous ecommerce object.
  send.push(option);
  logEvent(event, { ...ecommerceOption, ga4_event_name: ga4EventName });
};

/**
 * gtm ga4 search
 * @param {string} searchTerm
 * @param {object|undefined} gtm gtm object
 */
export const gtmSearch = (searchTerm, gtm) => {
  const send = getGtmDataLayer(gtm);
  send.push('search', { search_term: searchTerm });
};

/**
 * setTransactionProducts 建立 transactionProducts 參數
 * @param {{id: string, name: string, price: number, qty: number, gift: [], additionals: []}[]} items products
 * @param {{id: string, name: string, price: number, qty: number}[]} gifts global gifts
 * @return {{sku:string, name: string, category: string, price: number, quantity: number}[]} transactionProducts
 */
export const setTransactionProducts = (items = [], gifts = []) => {
  /** @const {{sku:string, name: string, category: string, price: number, quantity: number}[]} transactionProducts */
  const transactionProducts = [];

  // 商品
  items.forEach((item) => {
    transactionProducts.push({
      sku: item.id, // 商品貨碼
      name: item.name, // 商品名稱
      category: '', // 商品大分類名稱(L1)
      price: item.price, // 商品價格(攤完)
      quantity: item.qty, // 商品數量
    });

    // 商品內贈品
    (item.gifts || []).forEach((gift) => {
      transactionProducts.push({
        sku: gift.id,
        name: gift.name,
        category: '',
        price: gift.price,
        quantity: gift.qty,
      });
    });

    // 商品內加購品
    (item.additionals || []).forEach((gift) => {
      transactionProducts.push({
        sku: gift.id,
        name: gift.name,
        category: '',
        price: gift.price,
        quantity: gift.qty,
      });
    });
  });

  // 整車贈品
  gifts.forEach((y) => {
    transactionProducts.push({
      sku: y.id,
      name: y.name,
      category: '',
      price: y.price,
      quantity: y.qty,
    });
  });

  return transactionProducts;
};

/**
 * setPurchaseItems 建立 purchase 事件 items 參數
 * @param {{id: string, name: string, price: number, qty: number, gift: [], additionals: []}[]} items products
 * @param {{id: string, name: string, price: number, qty: number}[]} gifts global gifts
 * @return {{item_id:string, item_name: string, item_category: string, price: number, quantity: number}[]} transactionProducts
 */
export const setPurchaseItems = (items = [], gifts = []) => {
  /** @const {{sku:string, name: string, category: string, price: number, quantity: number}[]} transactionProducts */
  const purchaseItems = [];

  // 商品
  items.forEach((item) => {
    purchaseItems.push({
      item_id: item.id, // 商品貨碼
      item_name: item.name, // 商品名稱
      price: item.price, // 商品價格(攤完)
      quantity: item.qty, // 商品數量
    });

    // 商品內贈品
    (item.gifts || []).forEach((gift) => {
      purchaseItems.push({
        item_id: gift.id,
        item_name: gift.name,
        price: gift.price,
        quantity: gift.qty,
      });
    });

    // 商品內加購品
    (item.additionals || []).forEach((gift) => {
      purchaseItems.push({
        item_id: gift.id,
        item_name: gift.name,
        price: gift.price,
        quantity: gift.qty,
      });
    });
  });

  // 整車贈品
  gifts.forEach((y) => {
    purchaseItems.push({
      item_id: y.id,
      item_name: y.name,
      price: y.price,
      quantity: y.qty,
    });
  });

  return purchaseItems;
};

/**
 * gtm add datalayer for Pageview
 * @param {object} option option
 * @param {object|undefined} gtm gtm object
 */
export const gtmPageview = (option = {}, gtm) => {
  const send = getGtmDataLayer(gtm);

  const { pageTitle = '', pagePath = '' } = option;

  send.push({
    event: 'Pageview',
    pagePath,
    pageTitle,
  });

  logEvent('Pageview', {
    event: 'Pageview',
    pagePath,
    pageTitle,
  });
};

const getGoogleAnalyticsClientIdFromCookie = () => {
  const cookie = document.cookie.split(';').reduce((acc, curr) => {
    const [key, value] = curr.split('=').map((el) => el.trim());
    return { ...acc, [key]: value };
  }, {});
  // eslint-disable-next-line no-underscore-dangle
  return cookie._ga?.substring(6);
};

export const getGoogleAnalyticsClientId = () => {
  const isDev = import.meta.env.VITE_TYPE === 'development';
  let cid = '';
  // 取得 google cid
  try {
    // eslint-disable-next-line no-undef
    if (ga.getAll !== undefined) {
      cid = ga.getAll()[0].get('clientId');
    } else {
      cid = getGoogleAnalyticsClientIdFromCookie();
    }
    if (isDev) console.log(`cid: ${cid}`);
  } catch (error) {
    console.log(`can't get ga cid`);
    if (isDev) cid = '1876422535.1668044960';
  }
  return cid;
};

/**
 *
 * @param {{
 *  item_product_guid: string;
 *  item_name: string;
 *  price: number;
 *  quantity: number;
 * }[]} cartItems
 * @returns {{
 *  item_id: string;
 *  item_name: string;
 *  item_brand: string;
 *  item_category: string;
 *  item_category2: string;
 *  item_category3: string;
 *  price: number;
 *  quantity: number;
 * }[]}
 */

export const getGA4CategoriesAndBrandsList = async (cartItems = []) => {
  const ids = cartItems.map((item) => item.item_product_guid);
  try {
    const response = await fetchPurchaseGA4CategoriesAndBrands(ids);
    const categoriesData = response.data.items;

    return cartItems.map((cartItem) => {
      const categoriesDataItem = categoriesData.find((item) => item.item_product_guid === cartItem.item_product_guid);
      return {
        item_id: categoriesDataItem?.item_eslite_sn || '',
        item_name: categoriesDataItem.item_name,
        item_category: categoriesDataItem?.item_category || '',
        item_category2: categoriesDataItem?.item_category2 || '',
        item_category3: categoriesDataItem?.item_category3 || '',
        item_brand: categoriesDataItem?.item_brand || '',
      };
    });
  } catch (error) {
    console.log(error);
    return cartItems.map((cartItem) => ({
      ...cartItem,
      item_category: '',
      item_category2: '',
      item_category3: '',
      item_brand: '',
    }));
  }
};
