import { omit } from 'ramda';

const { NEW_RELIC_ENTITY_GUID } = import.meta.env;

function filterSensitiveData(response = {}) {
  const filtered = JSON.parse(JSON.stringify(response));
  if (filtered.headers && filtered.headers.Authorization) filtered.headers.Authorization = '***';

  return filtered;
}
const sendNewRelicLogs = ({ msgLog, level }) => {
  // Localhost 、LAB 不傳到 new relic
  if (!NEW_RELIC_ENTITY_GUID) {
    const log = JSON.parse(msgLog);
    console.group(`[NewRelic] logger info: ${log.message}`);
    console.log({
      level,
      log,
    });
    console.groupEnd();
    return;
  }

  window.newrelic.log(msgLog, { level });
};

export const newRelicLogInfo = (message, extra) => {
  const msgLog = JSON.stringify({
    message,
    info: extra,
  });
  sendNewRelicLogs({ msgLog, level: 'INFO' });
};

/**
 * 紀錄 API 500 status
 * @param message
 * @param error
 */
export const newRelicLoggerAPIStatus500 = (message, error = {}) => {
  const msgLog = JSON.stringify({
    message,
    extra: {
      headers: `${JSON.stringify(error.response?.headers)}`,
      data: `${JSON.stringify(error.response?.data)}`,
      method: `${error.config?.method}`,
      url: `${error.config?.url}`,
      response: `${JSON.stringify(error?.response)}`,
    },
  });
  sendNewRelicLogs({ msgLog, level: 'WARNING' });
};

/**
 * 紀錄進到 500 page
 * @param message
 * @param error
 */
export const newRelicLoggerLayout500 = ({ message, error = {} }) => {
  if (error?.config) error.config = filterSensitiveData(error.config);
  const errorInfo = error?.stack ? JSON.stringify(omit(['stack'], error)) : JSON.stringify(error);

  const msgLog = JSON.stringify({
    message,
    errorInfo,
  });
  sendNewRelicLogs({ msgLog, level: 'WARNING' });
};

/**
 * 紀錄異常的 API
 * @param response
 * @param uid
 * @param title
 */
export const sendAPIErrorToNewRelicLog = (response = {}, uid = '', title = `[api error] API異常`) => {
  const filteredResponse = filterSensitiveData(response);

  const msgLog = JSON.stringify({
    message: title,
    errorInfo: {
      uid,
      response: filteredResponse,
    },
  });
  sendNewRelicLogs({ msgLog, level: 'ERROR' });
};

/**
 * 紀錄 API 回覆錯的資料
 * @param message
 * @param response
 */
export const sendAnomaliesAPIToLog = (message, response) => {
  const msgLog = JSON.stringify({
    message,
    resData: response,
  });
  sendNewRelicLogs({ msgLog, level: 'INFO' });
};
